/*
Template Name: Hyper - Responsive Bootstrap 5 Admin Dashboard
Version: 3.4.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/

//Core files

@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'config/saas/variables';

@import './config/saas/light-mode';
@import './config/saas/dark-mode';
@import 'config/saas/custom-variables';

@import './node_modules/bootstrap/scss/bootstrap';

// Structure
@import 'custom/structure/general';
@import 'custom/structure/left-menu';
@import 'custom/structure/topbar';
@import 'custom/structure/right-sidebar';
@import 'custom/structure/page-head';
@import 'custom/structure/footer';
// horizontal nav
@import 'custom/structure/horizontal-nav';

//Fonts
@import 'custom/fonts/nunito';

//Components
@import 'custom/components/mixins';
@import 'custom/components/accordions';
@import 'custom/components/alert';
@import 'custom/components/avatar';
@import 'custom/components/breadcrumb';
@import 'custom/components/buttons';
@import 'custom/components/badge';
@import 'custom/components/card';
@import 'custom/components/custom-forms';
@import 'custom/components/dropdown';
@import 'custom/components/docs';
@import 'custom/components/forms';
@import 'custom/components/modal';
@import 'custom/components/nav';
@import 'custom/components/pagination';
@import 'custom/components/popover';
@import 'custom/components/print';
@import 'custom/components/progress';
@import 'custom/components/reboot';
@import 'custom/components/ribbons';
@import 'custom/components/switch';
@import 'custom/components/tables';
@import 'custom/components/type';
@import 'custom/components/utilities';
@import 'custom/components/widgets';
@import 'custom/components/social';
@import 'custom/components/steps';
@import 'custom/components/preloader';
@import 'custom/components/hero';

//Pages
@import 'custom/pages/authentication';
@import 'custom/pages/components-demo';
@import 'custom/pages/error';
@import 'custom/pages/faq';
@import 'custom/pages/maintenance';
@import 'custom/pages/tasks';
@import 'custom/pages/email';
@import 'custom/pages/timeline';

// Vendors
@import 'vendor/daterangepicker';
@import 'vendor/bootstrap-datepicker.min';
@import 'vendor/jquery.toast.min';
@import 'vendor/select2.min';
@import 'vendor/jquery.toast.min';
@import 'vendor/bootstrap-timepicker.min';
@import 'vendor/jquery.bootstrap-touchspin.min';

// Plugins
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/britechart';
@import 'custom/plugins/calendar';
@import 'custom/plugins/chartjs';
@import 'custom/plugins/datatable';
@import 'custom/plugins/daterange'; //react-range slider style
@import 'custom/plugins/datepicker'; //react-date picker style
@import 'custom/plugins/dragula';
@import 'custom/plugins/dropzone';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/maps'; // react-vector map style
@import 'custom/plugins/quill-editor';
@import 'custom/plugins/select2';
@import 'custom/plugins/simplebar';
@import 'custom/plugins/toaster';
@import 'custom/plugins/bootstrap-touchspin';
@import 'custom/plugins/bootstrap-timepicker';
@import 'custom/plugins/simplemde';
@import 'custom/plugins/typehead';
@import 'custom/plugins/sparklines';
@import 'custom/plugins/timepicker';
@import 'custom/plugins/rateit';
@import 'custom/plugins/rating';
@import 'custom/plugins/react-select'; // react select style
@import 'custom/plugins/react-table'; // react table style
@import 'custom/plugins/ion-rangeslider';
@import 'custom/plugins/jstree';
@import 'custom/plugins/frappe-gantt';
@import url('https://code.highcharts.com/dashboards/css/dashboards.css');
@import url('https://code.highcharts.com/dashboards/css/datagrid.css');

.ol-control {
    margin-right: 1em;
    margin-left: auto;
    width: 43.2px;
}

.ol-control > button {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border-radius: 1.5em;
    margin-bottom: 1em;
    right: 1.5em;
    width: 3em;
    height: 3em;
    border: none;
    box-shadow: 0px 5px 10px rgba(136, 136, 136, 0.5);
    transition: background-color 0.5s, color 0.5s;
    cursor: pointer;
}
.ol-overlaycontainer-stopevent {
    margin-top: 3rem;
}

.highcharts-datagrid-container {
    table {
        tbody {
            height: 400px;
        }
        thead > tr,
        tbody > tr {
            color: $input-color;
        }
    }
}
